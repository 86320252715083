import $ from 'jquery'
$(document).ready( initTeamSlider )

function initTeamSlider(){
  if ($('.js-slider-nav').length) {
    $('.js-slider-team').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.js-slider-nav'
    });
    $('.js-slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: '.js-slider-team',
      focusOnSelect: true,
      infinite: false
    });
  }
}
