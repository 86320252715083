import Headroom from 'headroom.js'
import $ from 'jquery'
import {blockScroll, unblockScroll} from "../../js/lib/block-scroll";

const mainNav = $('.js-main-nav')
const hamburger = $('.js-hamburger')

function showMainNav(event) {
  event.preventDefault()
  mainNav.toggleClass('is-active')
  hamburger.toggleClass('is-active')
  if (mainNav.hasClass('is-active')) {
    hamburger.attr('aria-expanded', 'true')
    blockScroll()
  } else {
    hamburger.attr('aria-expanded', 'false')
    unblockScroll()
  }
}

const initHeadroom = () => {
  const theHeader = document.querySelector('.js-header')
  const headroom = new Headroom(theHeader, {offset: 100, tolerance: 5})
  headroom.init()
}

const initMobileNav = () => {
  $(document).on('click', '.js-hamburger', showMainNav)
  $(document).on('keydown', function (event) {
    if (mainNav.hasClass('is-active') && (event.key == "Escape")) {
      hamburger.attr('aria-expanded', 'false')
      mainNav.toggleClass('is-active')
      hamburger.toggleClass('is-active')
    }
  })
  $(document).on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if (mainNav.hasClass('is-active')) {
        hamburger.attr('aria-expanded', 'false')
        mainNav.toggleClass('is-active')
        hamburger.toggleClass('is-active')
      }
    }
  })
}

$(document).ready(() => {
  initHeadroom()
  initMobileNav()
})
