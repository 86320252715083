import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider')
  const nextButton = $('.js-slide-next')

  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })

    nextButton.click(function () {
      sliderWrapper.slick('slickNext')
    })
  }
}
